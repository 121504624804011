<template>
  <div id="user-view">
    <v-row>
      <!-- User bio panel -->
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <!---<school-bio-panel
          :school-data="schoolData"
          @refetch-data="getschoolData"
        ></school-bio-panel>-->
      </v-col>

      <!-- User data panels and tabs -->
      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <user-tab-login-method
              v-if="schoolData.id"
              :user-data="schoolData"
              @refetch-data="getschoolData"
            >
            </user-tab-login-method>
          </v-tab-item>
          <v-tab-item>
            <component-user-login-details

              v-if="schoolData.id"
              :user="schoolData"
              user-type="Pupil"
            >
            </component-user-login-details>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import { mdiPhoneDial, mdiFormTextboxPassword } from '@mdi/js'
import ComponentUserLoginDetails from '../../../components/users/loginDetails.vue'
import schoolStoreModule from '../schoolStoreModule'

export default {
  components: {
    ComponentUserLoginDetails,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-school'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, schoolStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const schoolData = ref({})
    const schoolTab = ref(null)
    const isPlanUpgradeDialogVisible = ref(false)

    const getSchoolData = async () => {
      console.log('getSchoolData')
      store
        .dispatch('app-school/fetchSchool', { id: router.currentRoute.params.id })
        .then(response => {
          schoolData.value = response.data.school
        })
        .catch(error => {
          if (error.response.status === 404) {
            schoolData.value = {}
          }
        })
    }
    getSchoolData()

    const tabs = [
      { icon: mdiPhoneDial, title: 'Adresgegevens' },
      { icon: mdiFormTextboxPassword, title: 'Inloggegevens' },

      /*
      { icon: mdiLockOutline, title: 'Security' },
      { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      { icon: mdiBellOutline, title: 'Notifications' },
      { icon: mdiLinkVariant, title: 'Connections' },
      */
    ]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      getSchoolData,
      tabs,
      userTab: schoolTab,
      isPlanUpgradeDialogVisible,
      schoolData,
      resolveCurrentPlanValue,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
